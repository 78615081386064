import WhiteLogo from "../images/logo.svg";
import stripe from "../images/stripe.svg";
import { createTheme } from "@mui/material";
import { BackgroundImage } from "./OnboardingBackground";
import { ONBOARDING_BACKGROUND_SRC, WELCOME_BACKGROUND_SRC } from "../images/images";

const OnboardingBackground = () => {
    return <BackgroundImage
      imageSrc={ONBOARDING_BACKGROUND_SRC}
    />
}

const DashboardBackground = () => {
    return <BackgroundImage
      imageSrc={WELCOME_BACKGROUND_SRC}
    />
}
export const carbonclickTheme = createTheme({
    palette: {
        primary: {
            light: '#EAF9EC',
            main: '#4C9B5A'
        }
    },
    brightImgUrl: WhiteLogo,
    darkImgUrl : stripe,
    onboardingImage: OnboardingBackground,
    dashboardImage: DashboardBackground,
})