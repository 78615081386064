import React, { useState } from 'react'
import type { FormEvent } from 'react';
import { useNavigate } from 'react-router';
import { Layout } from "../../components/OnboardingLayout";
import {
    FormControlLabel,
    Checkbox,
    TextField,
    useTheme
} from '@mui/material'
import { formJson } from '../../utils/helperMethods';
import LoadingButton from '@mui/lab/LoadingButton';

const Register = () => {
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();

    const navigate = useNavigate();

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true);
        const data = formJson(e)
        const request = await fetch(`${process.env.REACT_APP_API_URL}/api/signup/`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(data)
        })
        const response = await request.json()

        if(response.customer) {
            navigate('/onboarding/behavior')
        }
    }


    return (
        <Layout
            title='Start Offsetting Carbon Emissions Today.'
            subTitle="Create your free carbonclick account to start offsetting."
            contentTitle="Account Information"
            stage={0}
        >
            <form onSubmit={submit}>
                <label>Name</label>
                <TextField required name='firstName' placeholder="John Doe" variant="outlined" />
                <label>Email</label>
                <TextField required type='email' name='email' placeholder="example123@gmail.com" variant="outlined" />
                <FormControlLabel control={<Checkbox required className='checkbox' sx={{
                    color: theme.palette.primary.main,
                    '&.Mui-checked': {
                        color: theme.palette.primary.main
                    },
                    '&.MuiSvgIcon-root': {
                        color: theme.palette.primary.main
                    },
                    ml: -1
                }} />} label={
                    <p className='terms'>By checking this box, you agree to CarbonClicks <a href="https://www.carbonclick.com/terms/merchants/" target="_blank">Terms and Conditions</a></p>
                } />
                <LoadingButton loading={isLoading} type="submit" variant='contained'>Start offsetting</LoadingButton>
            </form>
        </Layout>
    )
}

export default Register;