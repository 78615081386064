import '../styles/Attributes.scss';

export interface AttributeProps {
    children?: JSX.Element | JSX.Element[]
    active: boolean
}

export const AttributeContainer = ({ children, active }: AttributeProps) => {
    return (
        <div 
            className={`attribute-container ${active ? 'active' : ''}`} 
            style={{ display: 'flex', flexDirection: 'column' }}
        >
            {children}
        </div>  
    )
}

export const Attribute = ({ text }: { text: string }) => {
    return (
        <>
            <div className="attribute">
                <div />
                <p>{text}</p>
            </div>
        </>
    )
}