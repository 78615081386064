import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  styled,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  outlinedInputClasses,
  InputAdornment,
  Switch,
  FormGroup,
  useTheme,
} from "@mui/material";

import { ReactComponent as User } from "../../images/dashboard/user.svg";
import { ReactComponent as Store } from "../../images/dashboard/shopping-store.svg";
import { useUser } from "../../hooks/useUser";

const Behavior = () => {
  const [defaultRate, setDefaultRate] = useState(true);
  const { user, updateUser } = useUser();
  const theme = useTheme();

  const StyledSwitch = styled(Switch)(({ theme }) => ({
    height: "22px",
    width: "44px",
    padding: 0,

    "& .MuiButtonBase-root": {
      padding: 0,
      right: "1.5em",
    },
    "& .Mui-checked": {
      "& .MuiSwitch-thumb": {
        backgroundColor: "white",
      },
      "&:focus": {
        backgroundColor: "rgb(76, 155, 90) !important",
      },
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: theme.palette.primary.main,
      opacity: 1,
    },
    "& .MuiSwitch-track": {
      backgroundColor: "grey",
      borderRadius: 22 / 2,
      opacity: 1,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        width: 16,
        height: 16,
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "white",
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
      padding: 1,
    },
  }));
  return (
    <>
      <Grid container sx={{ marginTop: 5 }}>
        <Grid item xs={12} sm={12} md={6} sx={{ padding: 1 }}>
          <Box>
            <Typography variant="subtitle2" fontSize={"22px"}>
              Select your behavior method
            </Typography>
          </Box>
          <Grid container gap={1.5} mt={2}>
            <Grid
              item
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              md={5}
              sx={{
                border: `1.8px solid ${theme.palette.primary.main}`,
                borderRadius: 2,
                height: "17.5rem",
                backgroundColor: `${user?.behavior === 'default' ? theme.palette.primary.light : "#fff"}`,
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                updateUser({...user, behavior: 'default'});
              }}
            >
              <Box display={"flex"} justifyContent={"center"}>
                <User color={theme.palette.primary.main} />
              </Box>
              <Typography mt={1} textAlign={"center"} fontSize={"25px"}>
                Customer Pays
              </Typography>
              <Typography
                className="sub-text"
                marginTop={1.5}
                color={"#6B6B6B"}
                lineHeight={"15px"}
                textAlign={"center"}
                paddingX={2}
                fontSize={"14px"}
              >
                Free to you. Let your customers opt in to carbon neutral shipping.
              </Typography>
            </Grid>
            <Grid
              item
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              md={5}
              sx={{
                border: `1.8px solid ${theme.palette.primary.main}`,
                borderRadius: 2,
                height: "17.5rem",
                backgroundColor: `${user?.behavior === 'always_green' ? theme.palette.primary.light : "#fff"}`,
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                updateUser({...user, behavior: 'always_green'})
              }}
            >
              <Box display={"flex"} justifyContent={"center"}>
                <Store color={theme.palette.primary.main} />
              </Box>
              <Typography mt={1} textAlign={"center"} fontSize={"25px"}>
                Store Pays
              </Typography>
              <Typography
                className="sub-text"
                marginTop={1.5}
                color={"#6B6B6B"}
                lineHeight={"15px"}
                textAlign={"center"}
                fontSize={"14px"}
                paddingX={2}
              >
                Cover the cost of making your customer's orders carbon neutral
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={{ padding: 1 }}>
          <Box paddingLeft={2} paddingRight={4}>
            <Typography fontSize={"25px"}>Rate</Typography>
            <FormControl>
              {/* <FormLabel id="demo-radio-buttons-group-label">Rate</FormLabel> */}
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="default"
                name="radio-buttons-group"
                style={{ fontSize: "16px" }}
              >
                <Box mt={4}>
                  <FormControlLabel
                    value="default"
                    control={
                      <Radio color={`${!user?.flat_rate ? "primary" : "default"}`} />
                    }
                    checked={!user?.flat_rate}
                    label="Default Rate"
                    onClick={() => updateUser({...user, flat_rate: false})}
                  />
                  <Typography
                    className="sub-text"
                    fontSize={"16px"}
                    color={"#6B6B6B"}
                    lineHeight={"22px"}
                    textAlign={"left"}
                    paddingLeft={4}
                  >
                    Carbonclick will use factors like distance and product weight to calculate 
                    the exact amount of carbon produced by shipping the order.
                  </Typography>
                </Box>
                <Box mt={4}>
                  <FormControlLabel
                    value="Flat"
                    control={
                      <Radio color={`${user?.flat_rate ? "primary" : "default"}`} />
                    }
                    label="Flat Rate"
                    checked={user?.flat_rate}
                    onClick={() => updateUser({...user, flat_rate: true})}
                  />
                  <Typography
                    className="sub-text"
                    fontSize={"16px"}
                    color={"#6B6B6B"}
                    lineHeight={"22px"}
                    textAlign={"left"}
                    paddingLeft={4}
                  >
                    Pay a flat rate for each order.
                  </Typography>
                </Box>
              </RadioGroup>
            </FormControl>
            <Box paddingX={4} mb={2} mt={3}>
              <TextField
                variant="outlined"
                disabled={!user?.flat_rate}
                placeholder="100"
                value={(user?.flat_rate_amount_in_usd_cents || 0) / 100}
                onChange={(e) => updateUser({...user, flat_rate_amount_in_usd_cents: Number(e.target.value) * 100})}
                size={"small"}
                type="number"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      sx={{
                        borderRight: "1px solid black",
                      }}
                      position="start"
                    >
                      $
                    </InputAdornment>
                  ),
                }}
                sx={{
                  [`& .${outlinedInputClasses?.root}`]: {
                    backgroundColor: "white",
                    color: "black",
                  },
                  [` .${outlinedInputClasses.disabled}`]: {
                    backgroundColor: "#CCCCCC",
                  },
                }}
              ></TextField>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} mt="40px">
              <Typography fontSize={"22px"}>Checked by Default</Typography>
              <FormGroup>
                <FormControlLabel
                  label=""
                  labelPlacement="start"
                  sx={{
                    display: "flex",
                    gap: "1em",
                    m: 0,
                  }}
                  control={
                    <StyledSwitch disabled={false} onChange={() => updateUser({...user, default_checked: !user?.default_checked})} checked={user?.default_checked} />
                  }
                />
              </FormGroup>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Behavior;
