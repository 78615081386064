// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Backdrop, Box, Button, Card, CircularProgress, Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import Countup from "react-countup";
import InfoIcon from '@mui/icons-material/Info';
import Monitor from "../../images/dashboard/monitor.svg";
import moment from 'moment';
import Chart from 'react-apexcharts';

interface Stats {
  total: number;
  purchased: number;
  cart_conversion: number;
  last7: number[];
}

const Analytics = () => {
  const theme = useTheme();
  const [stats, setStats] = useState<Stats>();
  const [dates, setDates] = useState<string[]>();

  useEffect(() => {
    getStats();
    const newDates = [];
    for (let i = 6; i >= 0; i--) {
      newDates.push(moment().subtract(i, 'days').startOf('day').format('MMM Do')) 
    }
    setDates(newDates)
  }, [])

  const getStats = async () => {
    const request = await fetch(`${process.env.REACT_APP_API_URL}/api/orders/stats/`, { 
      credentials: 'include',
      method: 'GET' 
    });
    setStats(await request.json());
  }

  const getCount = (target: number) => {
    return <Countup end={target} duration={1.0} /> 
  }

  if(!stats) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: 99999 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const graph = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false
        },
        offsetY: 2
      },
      yaxis: {
        min: 0, 
        max: Math.max(...stats.last7) + 1,
        decimalsInFloat: 0,
        labels: {
          formatter: function(val: number) {
            return val.toFixed(0);
          }
        }
      },
      xaxis: {
        categories: dates
      },
      colors: [theme.palette.primary.main],
      stroke: {
        width: 4,
      },
      dataLabels: {
        enabled: false
      },
      title: { 
        text: 'Carbon Neutral Orders over the last 7 days',
        align: 'center',
        style: {
          fontSize:  '18px',
          fontWeight:  'bold',
          color:  theme.palette.primary.main
        }, 
      }
    },
    series: [
      {
        name: "Carbon Neutral Orders",
        data: stats.last7
      }
    ]
  };

  return (
    <>
      <Grid
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"row"}
        container
        height={'95%'}
        p={2}
        spacing={2}
      >
        <Grid item md={4}>
          <Card sx={{ p:3, height: '75%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
            <Typography variant={'h3'} color={theme.palette.primary.main}>{getCount(Math.round(stats.purchased / stats.total * 100))}%</Typography>
            <Typography textAlign={'center'} variant={'h6'} color={theme.palette.grey[500]}>of orders carbon neutral</Typography>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card sx={{ height: '75%', p:3, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
            <Typography variant={'h3'} color={theme.palette.primary.main}>{getCount(stats.purchased)}</Typography>
            <Typography textAlign={'center'} variant={'h6'} color={theme.palette.grey[500]}>Total carbon neutral orders</Typography>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card sx={{ height: '75%', p:3, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
            <Typography variant={'h3'} color={theme.palette.primary.main}>{getCount(Math.round(stats.cart_conversion * 100))}%</Typography>
            <Typography textAlign={'center'} variant={'h6'} color={theme.palette.grey[500]}>
              Increased cart conversion
              <Tooltip title="The increase in cart conversion since installing CarbonClick" arrow>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>
          </Card>
        </Grid>
        <Grid item md={12} flex={'1'}>
          <Card sx={{ height: '100%'}} >
          <Chart height={'100%'} type='area' options={graph.options} series={graph.series} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Analytics;
