import React, { FunctionComponent, SVGProps } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query'

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "./App.scss";
import Onboarding from "./pages/Onboarding";
import Dashboard from "./pages/Dashboard";
import Impact from "./pages/Impact";
import { Theme, ThemeProvider } from "@mui/material";
import { carbonclickTheme } from "./themes/carbonclick";
import { earthShareTheme } from "./themes/earthShare";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE || "");
const selectedTheme = process.env.REACT_APP_THEME;
const queryClient = new QueryClient()

declare module "@mui/material/styles" {
  interface Theme {
    brightImgUrl: string;
    darkImgUrl: string;
    onboardingImage: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
    dashboardImage: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
  }
  export interface ThemeOptions {
    brightImgUrl: string;
    darkImgUrl: string;
    onboardingImage: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
    dashboardImage: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
  }
}

let theme: Theme;

switch (selectedTheme) {
  case undefined:
    theme = carbonclickTheme;
    break;
  case "CARBONCLICK":
    theme = carbonclickTheme;
    break;
  case "EARTHSHARE":
    theme = earthShareTheme;
    break;
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <BrowserRouter>
          <Elements stripe={stripePromise}>
            <ThemeProvider theme={theme}>
              <Routes>
                <Route path="/onboarding/*" element={<Onboarding />} />
                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path="/impact/:estimateId" element={<Impact />} />
              </Routes>
            </ThemeProvider>
          </Elements>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  );
}

export default App;
