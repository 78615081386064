import { ReactComponent as CloseIconSvg } from '../images/icon-close.svg';
import { ReactComponent as TreesSvg } from '../images/trees.svg';
import { ReactComponent as SupportSvg } from '../images/support.svg';
import { ReactComponent as PeopleSvg } from '../images/people.svg';
import { ReactComponent as ReceiptSvg } from '../images/receipt.svg';
import { ReactComponent as CO2Svg } from '../images/co2.svg';
import { ReactComponent as VehicleSvg } from '../images/vehicle.svg';
export const Close = () => <CloseIconSvg />;
export const Trees = () => <TreesSvg />;
export const Support = () => <SupportSvg />;
export const Receipt = () => <ReceiptSvg />;
export const CO2 = () => <CO2Svg />;
export const Vehicle = () => <VehicleSvg />;
export const People = () => <PeopleSvg />;