import React, { useEffect } from 'react';
import { Layout } from '../../components/OnboardingLayout';
import { Button, useTheme } from '@mui/material';
import ExternalLink from '../../images/external-link.png';
import { ReactComponent as Designer } from '../../images/designer.svg';
import { useUser } from '../../hooks/useUser';

const Widget = () => {
    const { user, refreshUser } = useUser();
    const theme = useTheme();

    useEffect(() => {
        refreshUser();
    }, [])

    const navigateToStore = () => {
        window.location.href = `https://${user?.domain}/cart?carbonclick=debug`;
    }

    return (
        <Layout
            title='Design your widget.'
            subTitle="Design a widget that matches your company branding."
            className='widget-designer'
            stage={4}
        >
            <Designer color={theme.palette.primary.main} />
            <p style={{ padding: '10px' }}>Design your widget</p>
            <p className='regular' style={{ padding: '10px' }}>For this next step, you'll be taken to your store to design and place your carbon widget</p>
            <Button 
                variant='contained' 
                sx={{ width: 'fit-content!important', margin: '15px 0px!important' }}
                onClick={() => navigateToStore()}
            >
                {user?.domain}
                <img src={ExternalLink} style={{ width: '15px', minWidth: '0px', marginLeft: '10px' }} />
            </Button>
        </Layout>
    )
}

export default Widget;