import React, { useEffect, useState } from "react";
import type { FormEvent } from "react";
import { useNavigate } from "react-router";
import {
  Grid,
  Typography,
  Box,
  TextField,
  MenuItem,
  styled,
  useTheme,
} from "@mui/material";

import { ReactComponent as Plane } from "../../images/dashboard/plane.svg";
import { ReactComponent as Truck} from "../../images/dashboard/truck.svg";
import countries from '../../data/countries.json';
import { useUser } from "../../hooks/useUser";

const Shipping = () => {
  const { user, updateUser } = useUser();
  const theme = useTheme();

  const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      // height: "45px",
      fontSize: "16px",
      color: "black",
    },
  }));

  return (
    <>
      <Grid container sx={{ marginTop: 5 }}>
        <Grid item xs={12} sm={12} md={6} sx={{ padding: 1 }}>
          <Box>
            <Typography variant="subtitle2" fontSize={"22px"}>
              Select your shipping method
            </Typography>
          </Box>
          <Grid container gap={1.5} mt={2}>
            <Grid
              item
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              xs={12}
              sm={6}
              md={5}
              sx={{
                border: `1.8px solid ${theme.palette.primary.main}`,
                borderRadius: 2,
                height: "12.87rem",
                backgroundColor: `${
                  user?.default_shipping_mode === "air" ? theme.palette.primary.light : "#fff"
                }`,
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                updateUser({...user, default_shipping_mode: "air"});
              }}
            >
              <Box display={"flex"} justifyContent={"center"}>
                <Plane color={theme.palette.primary.main} />
              </Box>
              <Typography mt={1} textAlign={"center"} fontSize={"25px"}>
                Air
              </Typography>
            </Grid>
            <Grid
              item
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              xs={12}
              sm={6}
              md={5}
              sx={{
                border: `1.8px solid ${theme.palette.primary.main}`,
                borderRadius: 2,
                height: "12.87rem",
                backgroundColor: `${
                  user?.default_shipping_mode === "ground" ? theme.palette.primary.light : "#fff"
                }`,
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                updateUser({...user, default_shipping_mode: "ground"});
              }}
            >
              <Box display={"flex"} justifyContent={"center"}>
                <Truck color={theme.palette.primary.main} />
              </Box>
              <Typography mt={1} textAlign={"center"} fontSize={"25px"}>
                Ground
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={{ padding: 1 }}>
          <Box paddingLeft={2} paddingRight={4}>
            <Typography variant="subtitle2" fontSize={"22px"}>
              Country of Origin
            </Typography>
            <StyledTextField
              id="outlined-select-country"
              select
              value={user?.default_from_country}
              fullWidth
              onChange={(e) => {
                updateUser({...user, default_from_country: e.target.value})
              }}
              SelectProps={{
                displayEmpty: true,
              }}
            >
              <MenuItem value={""} disabled>
                Select Country
              </MenuItem>
              {countries.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.name}
                </MenuItem>
              ))}
            </StyledTextField>
            <Typography mt={2} variant="subtitle2" fontSize={"22px"}>
              Postal Code
            </Typography>
            <Box width={{ xs: "100%", sm: "40%", md: "40%" }}>
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                value={user?.default_from_postal_code}
                onChange={(e) => {
                  e.preventDefault()
                  updateUser({...user, default_from_postal_code: e.target.value})
                }}
              />
            </Box>
            <Typography mt={2} variant="subtitle2" fontSize={"22px"}>
              Average Product Weight (grams)
            </Typography>
            <Box width={{ xs: "100%", sm: "40%", md: "40%" }}>
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                type='number'
                onChange={(e) => {
                  updateUser({...user, default_item_weight_in_grams: Number(e.target.value)})
                }}
                value={user?.default_item_weight_in_grams}
              />
            </Box>
            <Typography
              className="sub-text"
              paddingRight={2}
              marginTop={2}
              fontSize={"16px"}
              color={"#6B6B6B"}
              lineHeight={"15px"}
              fontFamily={"NunitoSans-Regular"}
            >
              When your product has no weight assigned in shopify, we use this number as a backup to determin
              the carbon emissions of shipping it.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Shipping;
