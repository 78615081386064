import React, { useEffect} from "react";
import '../styles/Onboarding.scss';
import Welcome from "./onboarding/Welcome";
import Register from "./onboarding/Register";
import Behavior from "./onboarding/Behavior";
import Widget from "./onboarding/Widget";
import Payment from "./onboarding/Payment";
import {
    Routes,
    Route,
} from "react-router-dom";
import Shipping from "./onboarding/Shipping";
import { useUser } from "../hooks/useUser";

const Onboarding = () => {
    const { refreshUser } = useUser();

    useEffect(() => {
        refreshUser();
    }, [])

    return (
        <div className="onboarding">
            <Routes>
                <Route path='/welcome' element={<Welcome />} />
                <Route path='/register' element={<Register />} />
                <Route path='/shipping' element={<Shipping />} />
                <Route path='/behavior' element={<Behavior />} />
                <Route path='/payment' element={<Payment />} />
                <Route path='/widget' element={<Widget />} />
            </Routes>
        </div>
    )
};

export default Onboarding;