import React from 'react'
import { styled } from "@mui/material";

const StyledBackgroundImg = styled('img')({
  minWidth: '100%',
  minHeight: '100%'
});
const BackgroundImage = ({imageSrc}:{imageSrc: string}) => {
  return <StyledBackgroundImg
              src={imageSrc}
              alt={'background-image'}></StyledBackgroundImg>
}

export {
  BackgroundImage
}