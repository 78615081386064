import { useParams, useLocation } from "react-router-dom";
import { Modal, Box, Button, Typography, Grid, Backdrop, CircularProgress, styled, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Estimate } from "../types";
import { Close, CO2, Trees, Support, People, Receipt, Vehicle } from '../images/icons';

const StyledImpactModal = styled(Modal)(({ theme }) => ({
  fontFamily: 'NunitoSans-SemiBold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    alignItems: 'end',
  },
  '& .impact-container': {
    width: '1000px',
    height: '800px',
    maxWidth: '100%',
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '85%',
      maxHeight: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '95%',
    },
    backgroundColor: 'white',
    overflowY: 'scroll',
    '& .close-button-container': {
      display: 'flex',
      justifyContent: 'flex-end',
      top: '0',
      right: '0',
      position: 'sticky',
      padding: '4px 4px 0 0 ',
    }
  },

}));
const Impact = () => {
  const [openModal, setOpenModal] = useState(true);
  const [isGreen, setIsGreen] = useState('false');
  const [loading, setLoading] = useState(false);
  const { estimateId } = useParams();
  const { search } = useLocation();
  const [estimate, setEstimate] = useState<Estimate>({
    co2: 0,
    project: {
      shortDescription: '',
      images: [],
      thumbnailImageUrl: '',
      url: '',
      name: ''
    },
    num_offset: 0,
    num_people: 0,
  });

  const getEstimate = async (id: string) => {
    setLoading(true);
    
    const estimateRequest = await fetch(`${process.env.REACT_APP_API_URL}/api/estimates/?id=${id}`)

    const estimate = await estimateRequest.json();

    setEstimate(estimate)

    setLoading(false);
  }

  useEffect(() => {
    const params = new URLSearchParams(search);
    setIsGreen(params.get('isGreen') || 'false')
    if (estimateId) getEstimate(estimateId);
  }, [])

  const close = () => {
    window.parent.postMessage({ action: "close" }, "*")
  }

  const checkbox = () => {
    window.parent.postMessage({ action: "carbonclick_check" }, "*")
    close();
  }

  const numberWithCommas = (x: number) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className='main-container' >
      <Backdrop sx={{ color: '#fff', zIndex: 99999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <StyledImpactModal
        open={openModal}
        onClose={() => close()}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={'impact-container'}>
          <Box className={'close-button-container'}>
            <IconButton
              sx={{ backgroundColor: 'white'}}
              onClick={close}>
              <Close />
            </IconButton>
          </Box>
          <Grid container p={4} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '8px'
          }}>
            <Grid xs={12}>
              <Typography variant="h5">Reduce the climate impact of your purchase</Typography>
            </Grid>
            <Grid xs={12} mt={1}>
              <Typography color={"#696969"}>
                We’re taking action to reduce the impact of our business on the planet. That’s why we’ve partnered with our friends at CarbonClick. Together, we offer simple, meaningful and trustworthy climate action. Come join us on our climate journey.
              </Typography>
            </Grid>
            <Grid xs={12} mt={2}>
              <Typography color={"#696969"} className='bold'>
                By contributing you will:
              </Typography>
            </Grid>
            <Grid xs={2} sm={1} mt={2}>
              <Box p={1} style={{
                backgroundColor: '#E8FFEC',
                height: '30px',
                width: '30px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Trees />
              </Box>
            </Grid>
            <Grid xs={11} mt={2}>
              <Typography color={"#696969"}>
                Reduce the climate impact of your purchase.
              </Typography>
            </Grid>
            <Grid xs={2} sm={1} mt={2}>
              <Box p={1} style={{
                backgroundColor: '#E8FFEC',
                height: '30px',
                width: '30px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Support />
              </Box>
            </Grid>
            <Grid xs={11} mt={2}>
              <Typography color={"#696969"}>
                Support a carefully selected mix of local and international projects that fight climate change. Learn more about them <a style={{textDecoration: 'none'}} href='https://my.carbon.click/baskets'><strong style={{ color: '#4C9B5A', cursor: 'pointer' }}>here.</strong></a>
              </Typography>
            </Grid>
            <Grid xs={2} sm={1} mt={2}>
              <Box p={1} style={{
                backgroundColor: '#E8FFEC',
                height: '30px',
                width: '30px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <People />
              </Box>
            </Grid>
            <Grid xs={11} mt={2}>
              <Typography color={"#696969"}>
                Join the movement of <strong style={{ color: '#4C9B5A' }}>{numberWithCommas(estimate.num_people)}</strong> other individuals who have together offset <strong style={{ color: '#4C9B5A' }}>{numberWithCommas(Math.round(estimate.num_offset))}</strong> kg of CO2.
              </Typography>
            </Grid>
            <Grid xs={2} sm={1} mt={2}>
              <Box p={1} style={{
                backgroundColor: '#E8FFEC',
                height: '30px',
                width: '30px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Receipt />
              </Box>
            </Grid>
            <Grid xs={11} mt={2}>
              <Typography color={"#696969"}>
                Receive a receipt allowing you track your funds. We believe in 100% transparency.
              </Typography>
            </Grid>
            <Grid xs={12} mt={2}>
              <Typography color={"#696969"} className='bold'>
                What's the power of your contribution?
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box p={2} style={{
                  backgroundColor: '#F8FEFA',
                  border: '1px solid #97D8A2',
                  borderRadius: '3px',
                }}>
                  <Grid container style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <Grid xs={12} style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <CO2 />
                    </Grid>
                    <Grid xs={12} style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }} mt={2}>
                      <Typography color="#4C9B5A" className="bold" fontSize={'22px'}>{numberWithCommas(Math.round(estimate.co2))} kgs</Typography>
                    </Grid>
                    <Grid xs={12} style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }} mt={2}>
                      <Typography>CO2 Offset</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={2} style={{
                  border: '1px solid #97D8A2',
                  borderRadius: '3px',
                }}>
                  <Grid container style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <Grid xs={12} style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <Vehicle />
                    </Grid>
                    <Grid xs={12} style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }} mt={2}>
                      <Typography color="#4C9B5A" className="bold" fontSize={'22px'}>{numberWithCommas(Math.round(estimate.co2 / 0.4))} Miles</Typography>
                    </Grid>
                    <Grid xs={12} style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }} mt={2}>
                      <Typography>Distance driven</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} md={6} pr={1} mt={1}>

            </Grid>
            <Grid xs={12} sm={12} md={6} mt={1} pl={1}>

            </Grid>
            <Grid xs={12} mt={2} style={{ position: 'sticky' }}>
              <Button fullWidth variant="contained" style={{ backgroundColor: '#24AA3C' }} onClick={() => checkbox()}>{isGreen === 'true' ? "Your order will be carbon neutral!" : "Add to offset your carbon footprint"}</Button>
            </Grid>
          </Grid>
        </Box>
      </StyledImpactModal>
    </div>
  );
};

export default Impact;
