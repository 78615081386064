import type { MouseEvent } from 'react';
import '../styles/BoxWithIcon.scss';

export interface BoxProps {
    item: string
    text: string
    icon: string
    active?: boolean
    onClick: (event: MouseEvent<HTMLDivElement>, item: string) => void
}

export const BoxWithIcon = ({item, text, icon, active, onClick}: BoxProps) => {
    return (
        <div onClick={(e) => onClick(e, item)} className={`box-with-icon ${active ? 'active' : ''}`}>
            <img src={icon} />
            <p>{text}</p>
        </div>
    )
}