import React from "react";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import {ReactComponent as Monitor} from "../../images/dashboard/monitor.svg";
import { useUser } from "../../hooks/useUser";

const Design = () => {
  const { user } = useUser();
  const theme = useTheme();

  return (
    <>
      <Grid
        height={"100%"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          gap={2}
        >
          <Box display={"flex"} justifyContent={"center"}>
            <Monitor style={{ marginTop: "2rem" }} color={theme.palette.primary.main} />
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            <Typography
              variant="h4"
              fontSize={{ xs: "26px", sm: "30px", md: "35px" }}
              fontWeight={"medium"}
              textAlign={"center"}
              width={{ xs: "90%", sm: "50%", md: "30%" }}
              color={theme.palette.primary.main}
            >
              Design Your Widget
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            <Typography
              className="sub-text"
              fontSize={"16px"}
              color={"#6B6B6B"}
              lineHeight={"18px"}
              textAlign={"center"}
              width={{ xs: "90%", sm: "60%", md: "40%" }}
            >
              Customize the carbonclick widget by using the designer!
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            <Button
              sx={{
                textTransform: "capitalize",
                height: "4rem",
                width: "16rem",
                fontSize: "25px",
              }}
              color="primary"
              variant="contained"
              onClick={() => window.location.href = `https://${user?.domain}?carbonclick=debug`}
            >
              Widget Designer
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default Design;
