import React, { useState } from 'react';
import type { FormEvent } from 'react';
import { useNavigate } from 'react-router';
import { Layout } from "../../components/OnboardingLayout";
import {
    TextField
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton'; 

const Shipping = () => {
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setTimeout(() => {
            navigate('/onboarding/behavior')
        }, 1000)   
    }

    return (
        <Layout
            stage={1}
            title="Where do your products come from?"
            subTitle="We use your shipping information to help calculate the carbon offset."
            contentTitle="Shipping Information"
        >
            <form onSubmit={submit}>
                <label>Origin Postal Code</label>
                <TextField placeholder="Enter Postal Code" variant="outlined" />
                <LoadingButton loading={isLoading} type="submit" variant='contained'>Save and Continue</LoadingButton>
            </form>
        </Layout>
    )
}

export default Shipping;