import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { Layout } from "../components/DashboardLayout";
import { useUser } from "../hooks/useUser";
import Analytics from "./dashboard/Analytics";
import Behavior from "./dashboard/Behavior";
import Design from "./dashboard/Design";
import FulFillment from "./dashboard/FulFillment";
import Orders from "./dashboard/Orders";
import Shipping from "./dashboard/Shipping";

const Dashboard = () => {
  const { search } = useLocation();

  const { refreshUser } = useUser();

  const login = async () => {
    if(search) {
      const loginRequest = await fetch(`${process.env.REACT_APP_API_URL}/login/shopify/${search}`, { 
        credentials: 'include' 
      })
    }
    
    refreshUser();
  }

  useEffect(() => {
    login()
  }, [])

  const Location = useLocation();

  const getPathwayAndEndPoint = (): { endPoint: string; pathway: string[] } => {
    const pathway = Location.pathname.split("/");
    pathway.reverse();
    let endPoint = pathway[0];

    return {
      endPoint: endPoint.toLowerCase(),
      pathway: pathway,
    };
  };

  const [selectedContent, setSelectedContent] = useState<string>("");
  const [selectedContentEditable, setSelectedContentEditable] =
    useState<boolean>(false);

  useEffect(() => {
    const { pathway, endPoint } = getPathwayAndEndPoint();

    if (
      pathway[1] === "dashboard" &&
      ["shipping", "behavior", "design", "fullfilment", 'analytics', 'design', 'orders'].includes(endPoint)
    ) {
      setSelectedContent(endPoint);
      setSelectedContentEditable(endPoint !== "design");
    }
  }, [Location]);

  return (
    <Layout
      pageTitle="Shopify App"
      contentTitle={selectedContent}
      contentEditable={selectedContentEditable}
    >
      <Routes>
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/behavior" element={<Behavior />} />
        <Route path="/design" element={<Design />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/fullfilment" element={<FulFillment />} />
        <Route path="/orders" element={<Orders />} />
      </Routes>
    </Layout>
  );
};

export default Dashboard;
