import Cookies from 'universal-cookie';

export interface User {
    domain?: string;
    is_enabled?: boolean;
    default_from_country?: string;
    default_shipping_mode?: 'air' | 'ground';
    default_item_weight_in_grams?: number;
    default_from_postal_code?: string;
    behavior?: 'always_green' | 'default';
    flat_rate?: boolean;
    flat_rate_amount_in_usd_cents?: number;
    default_checked?: boolean;
    sku_format?: string;
    force_fulfillment?: boolean;
    use_product_for_order?: boolean;
}

// helper to get user from localstorage
export function getStoredUser(): User {
  const storedUser = localStorage.getItem('user');
  return storedUser ? JSON.parse(storedUser) : null;
}

export function setStoredUser(user: Partial<User>) {
  localStorage.setItem('user', JSON.stringify(user));
}

export function clearStoredUser() {
  localStorage.removeItem('user');
}
