import React from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  MenuItem,
  styled,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useUser } from "../../hooks/useUser";

const FulFillment = () => {
  const { user, updateUser } = useUser();
  const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      height: "45px",
      fontSize: "22px",
      color: "black",
    },
  }));

  return (
    <>
      <Grid container sx={{ marginTop: 5 }}>
        <Grid item xs={12} sm={12} md={6} sx={{ padding: 1 }}>
          <Box paddingRight={4}>
            <Typography fontSize={"22px"}>Variant SKU</Typography>
            <Box my={2}>
              <StyledTextField
                variant="outlined"
                value={user?.sku_format}
                onChange={(e) => updateUser({...user, sku_format: e.target.value})}
                fullWidth
              ></StyledTextField>
            </Box>
            <Typography
              className="sub-text"
              paddingRight={5}
              fontSize={"16px"}
              color={"#6B6B6B"}
              lineHeight={"px"}
              textAlign={"left"}
            >
              The custom shipping SKU carbonclick will automatically set
            </Typography>
            <Typography mt={2} fontSize={"25px"}>
              Shopify Fullfilment
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                checked={user?.force_fulfillment}
                onChange={() => updateUser({...user, force_fulfillment: !user?.force_fulfillment})}
                label="Force Fullfilment"
              />
              <Typography
                className="sub-text"
                paddingLeft={4}
                fontSize={"16px"}
                color={"#6B6B6B"}
                lineHeight={"px"}
                textAlign={"left"}
              >
                Automatically mark the carbonclick product as fulfilled in your 3PL and o rders sections
              </Typography>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                checked={user?.use_product_for_order}
                onChange={() => updateUser({...user, use_product_for_order: !user?.use_product_for_order})}
                label="Use Product For Order"
              />
              <Typography
                className="sub-text"
                paddingLeft={4}
                fontSize={"16px"}
                color={"#6B6B6B"}
                lineHeight={"px"}
                textAlign={"left"}
              >
                Attach the carbonclick estimate id to the product, instead of the order notes
              </Typography>
            </FormGroup>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6} sx={{ padding: 1 }}>
          <Box padding={3} sx={{ backgroundColor: "#EAF9EC", borderRadius: 2 }}>
            <Typography fontSize={"25px"}> Fullfilment Options</Typography>
            <Typography
              className="sub-text"
              fontSize={"16px"}
              lineHeight={"px"}
              textAlign={"left"}
              marginTop={1}
            >
              By default, our Shopify app should work with your existing fulfillment system. The Carbonclick carbon offset is a digital product that does not require shipping. However, with some fulfillment systems, additional steps are needed to completely fulfill orders with carbon offsets. Because there are so many variations of Shopify fulfillment setups, we have two options for fulfillment help:
            </Typography>
            <Typography mt={1} fontSize={"22px"}>
              {" "}
              Force Fullfilment
            </Typography>
            <Typography
              className="sub-text"
              fontSize={"16px"}
              lineHeight={"px"}
              textAlign={"left"}
              marginTop={1}
            >
              Phasellus sit amet porttitor odio. Aliquam bibendum erat at massa
              aliquet ultrices. Duis iaculis, purus et ullamcorper aliquet,
              nulla ipsum interdum justo, sit amet cursus nisl lorem vel sapien.
              Curabitur pharetra magna vitae diam tristique, eu faucibus dolor
              eleifend. Sed molestie ornare molestie. Etiam a urna ac orci
              vehicula pretium. Nunc suscipit ex at dolor lacinia, eget
              hendrerit enim mattis. Donec hendrerit mauris in ex efficitur, ac
              tincidunt libero aliquam. Sed sit amet lacus porttitor, cursus
              diam ut, tempus erat.
            </Typography>
            <Typography mt={1} fontSize={"22px"}>
              {" "}
              Use Product For Order
            </Typography>
            <Typography
              className="sub-text"
              fontSize={"16px"}
              lineHeight={"18px"}
              textAlign={"left"}
              marginTop={1}
            >
              Nam eu malesuada tortor, nec ullamcorper lectus. Nullam sit amet
              nisl turpis. Curabitur tempor volutpat metus, dapibus sodales orci
              ultricies a. Donec tellus sapien, pulvinar vel quam ut, laoreet
              eleifend libero. Sed iaculis consequat ex, et suscipit est blandit
              et. Integer maximus porta elit ac placerat.
              <br />
              <br />
              Donec at gravida ante, sit amet porta orci. Pellentesque at nisi
              dolor. Etiam at quam dolor. In tellus dui, molestie pretium nulla
              id, ornare gravida elit.
            </Typography>
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
};

export default FulFillment;
