import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Layout } from "../../components/OnboardingLayout"
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import LoadingButton from '@mui/lab/LoadingButton';
import { Token } from '@stripe/stripe-js';
import { AttributeContainer, Attribute } from '../../components/Attributes';
import type { MouseEvent } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Payment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [ selected, setSelected ] = useState('default')

  const navigate = useNavigate();

  const stripeTokenHandler = async (token: Token) => {
    const paymentData = {token: token.id};

    const request = await fetch(`${process.env.REACT_APP_API_URL}/api/shop/`, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(paymentData)
    });
    const response = await request.json();
    if(response.ok) {
      navigate('/onboarding/widget')
    } else {
      alert(response.message)
      setIsLoading(false);
    }
  }

  const submit = async () => {
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    if (card) {
      const result = await stripe.createToken(card);
      if (result.error) {
        // Show error to your customer.
        setIsLoading(false);
        alert(result.error.message);
      } else {
        // Send the token to your server.
        // This function does not exist yet; we will define it in the next step.
        stripeTokenHandler(result.token);
      }
    }

  }

  const setShopify = async () => {
    const request = await fetch(`${process.env.REACT_APP_API_URL}/api/payments/`, {
      credentials: 'include',
    });
    const response = await request.json();
    window.location.href = response.url
  }

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',        
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleSelection = (e: MouseEvent<HTMLDivElement>, item: string) => {
    setSelected(item)
}

  return (
    <Layout
    title='Payment details'
    contentTitle="Choose your payment method"
    subTitle="Funds for offsetting will be collected from your customers by you. We then collect these funds from you."
    stage={3}
    >
      <Accordion
        defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}          
          >
          <label>Charge to your Shopify bill</label>
        </AccordionSummary>
        <AccordionDetails>
          <AttributeContainer active={true}>
            <Attribute text='As your customers purchase offsets, charges are applied to your Shopify bill' />
          </AttributeContainer>
          <LoadingButton loading={isLoading} onClick={() => setShopify()} variant='contained' sx={{ margin: '15px 0px!important' }}>Continue</LoadingButton>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}          
          >
          <label>Charge to your Card</label>
        </AccordionSummary>
        <AccordionDetails>
          <AttributeContainer active={true}>
            <Attribute text='Your card will be charged for offsets every 14 days.' />
          </AttributeContainer>
          <CardElement options={CARD_ELEMENT_OPTIONS} />      
          <LoadingButton loading={isLoading} onClick={() => submit()} variant='contained' sx={{ margin: '15px 0px!important' }}>Submit Card</LoadingButton>
        </AccordionDetails>
      </Accordion>

    </Layout>
  )
}

export default Payment;