// @ts-nocheck
import React, { useState } from "react";
import {
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider,
  ListItemIcon,
  Button,
  styled,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  useTheme,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { capitalize } from "../utils/helperMethods";

import "../styles/Dashboard.scss";

import NavbarBackground from "../images/dashboard/navbar_backgorund_logo.svg";
import ShippingIcon from "../images/dashboard/shipping.svg";
import BehaviorIcon from "../images/dashboard/behaviour.svg";
import DesignIcon from "../images/dashboard/design.svg";
import FullfilmentIcon from "../images/dashboard/checked.svg";
import LaunchIcon from '@mui/icons-material/Launch';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Orders from '../images/dashboard/orders.png';
import { useUser } from "../hooks/useUser";
import { LogoComponent } from "./LogoComponent";

export interface LayoutProps {
  pageTitle: string;
  contentTitle: string;
  contentEditable: boolean;
  children?: JSX.Element | JSX.Element[];
}

const Navlinks = [
  {
    name: "Dashboard",
    url: process.env.REACT_APP_PORTAL_URL || '',
    iconSrc: <LaunchIcon style={{ color: 'white', marginLeft: '-3px' }} />
  },
  {
    name: "Shipping",
    url: "/dashboard/shipping",
    iconSrc: ShippingIcon,
  },
  {
    name: "Behavior",
    url: "/dashboard/behavior",
    iconSrc: BehaviorIcon,
  },
  {
    name: "Design",
    url: "/dashboard/design",
    iconSrc: DesignIcon,
  },
  {
    name: "Orders",
    url: "/dashboard/orders",
    iconSrc: Orders,
  },
  {
    name: "Analytics",
    url: "/dashboard/analytics",
    iconSrc: <AnalyticsIcon style={{ color: 'white', marginLeft: '-3px' }} />
  },
  {
    name: "Fullfilment",
    url: "/dashboard/fullfilment",
    iconSrc: FullfilmentIcon,
  },
];

export const Layout = ({
  pageTitle,
  contentTitle,
  contentEditable,
  children,
}: LayoutProps) => {
  const { user, diff, flushUser, updateUser } = useUser();
  const theme = useTheme();
  const Location = useLocation();

  const StyledSwitch = styled(Switch)(({ theme }) => ({
    height: "22px",
    width: "44px",
    padding: 0,
    "& .MuiButtonBase-root": {
      padding: 0,
      right: "1.5em",
    },
    "& .Mui-checked": {
      "& .MuiSwitch-thumb": {
        backgroundColor: "white",
      },
      "&:focus": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    "& .MuiSwitch-track": {
      backgroundColor: "white",
      borderRadius: 22 / 2,
      opacity: 1,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        width: 16,
        height: 16,
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
      padding: 1,
    },
  }));

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    color: "white",
    "& .MuiListItemButton-root": {
      display: "flex",
      padding: "0",
    },
    "& .MuiListItemIcon-root": {
      padding: "0.5em 0.8rem 0.5em 0.8em",
      marginLeft: "1rem",
      minWidth: "fit-content",
      maxWidth: "fit-content",
      "&:before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        minHeight: "100%",
        minWidth: "0.6em",
        borderTopRightRadius: "3px",
        borderBottomRightRadius: "3px",
        opacity: 0.85,
      },
    },
    "& .MuiTypography-root": {
      lineHeight: 1,
      fontSize: "1.25em",
    },
    "& .Mui-selected": {
      backgroundColor: "transparent",
      "& .MuiListItemIcon-root:before": {
        backgroundColor: "white",
      },
    },
  }));

  const [profileMenuAnchorEl, setProfileMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
  };

  return (
    <Box
      className="dashboard-layout"
      style={{ overflow: 'hidden' }}
    >
      <div style={{ height: '100vh', width:'100vw' }}>
        <theme.dashboardImage width={'100%'} height={'100%'} viewBox={'0 0 100% 100%'} />
      </div>
      <Box sx={{ position: 'absolute', display: 'flex', height: '100vh', width: '100vw' }}>
        <Box className="nav-bar">
          <Box className="nav-bar-top">
            <Box>
              <LogoComponent darkMode={false} />
            </Box>
          </Box>
          <Box
            className="nav-bar-content"
            sx={{ backgroundImage: `url(${NavbarBackground})`, backgroundColor: theme.palette.primary.main }}
          >
            <List>
              {Navlinks.map(({ name, url, iconSrc }, index) => {
                if (url.includes('https')) {
                  return (
                    <a href={url} key={name} style={{ textDecoration: 'none' }}>
                      <StyledListItem sx={{ paddingLeft: 0 }}>
                        <ListItemButton selected={Location.pathname === url}>
                          <ListItemIcon>
                            {typeof iconSrc === 'string' ? <img width={"18px"} src={iconSrc} /> : iconSrc}
                          </ListItemIcon>
                          <ListItemText primary={name} />
                        </ListItemButton>
                      </StyledListItem>
                    </a>
                  )
                }
                return (
                  <NavLink
                    key={index}
                    to={url}
                    style={{
                      textDecoration: "none",
                      color: "white !important",
                    }}
                    className={"active_link"}
                  >
                    <StyledListItem sx={{ paddingLeft: 0 }}>
                      <ListItemButton selected={Location.pathname === url}>
                        <ListItemIcon>
                          {typeof iconSrc === 'string' ? <img width={"18px"} src={iconSrc} /> : iconSrc}
                        </ListItemIcon>
                        <ListItemText primary={name} />
                      </ListItemButton>
                    </StyledListItem>
                  </NavLink>
                )
              })}
            </List>
          </Box>
        </Box>
        <Box
          id="dashboard-main-content"
          className="main-content"
          sx={
            {
              // "&:before": {
              //   content: '""',
              //   backgroundImage: `url(${
              //     contentTitle == "shipping" || contentTitle == "behavior"
              //       ? contentPageLogos[contentTitle]
              //       : ""
              //   })`,
              // },
            }
          }
        >
          <Box className="primary-top-bar">
            <Box className="primary-top-bar-content">
              <Box>{user?.domain}</Box>
              {/* <IconButton>
              <img width={"25px"} height={"25px"} src={HelpIcon} />
            </IconButton> */}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {/* <Tooltip title="Account settings">
                <IconButton
                  onClick={handleAvatarClick}
                  size="small"
                  sx={{ p: 0 }}
                  aria-controls={
                    profileMenuAnchorEl ? "account-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={profileMenuAnchorEl ? "true" : undefined}
                >
                  <StyledAvatar src={""} />
                </IconButton>
              </Tooltip> */}
              </Box>
              {/* <StyledMenu
              anchorEl={profileMenuAnchorEl}
              id="account-menu"
              open={Boolean(profileMenuAnchorEl)}
              onClose={handleProfileMenuClose}
              onClick={handleProfileMenuClose}
              PaperProps={{
                elevation: 0,
              }}
              transformOrigin={{ horizontal: "center", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <StyledAvatar /> My account
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </StyledMenu> */}
            </Box>
          </Box>
          <Box className="secondary-top-bar">
            <Box className="page-title">{pageTitle}</Box>
            <FormGroup>
              <FormControlLabel
                label="Enable Shopify App"
                labelPlacement="start"
                sx={{
                  display: "flex",
                  gap: "1em",
                  m: 0,
                }}
                control={<StyledSwitch checked={user?.is_enabled} onChange={() => {
                  updateUser({ ...user, is_enabled: !user?.is_enabled })
                }} />}
              />
            </FormGroup>
          </Box>
          <Box className="page-content">
            <>
              {contentTitle !== "design" && (contentTitle || contentEditable) ? (
                <>
                  <Box className="page-content-top-bar">
                    {contentTitle ? (
                      <Box className="content-title" style={{ color: theme.palette.primary.main }}>
                        {capitalize(contentTitle)}
                      </Box>
                    ) : (
                      <></>
                    )}
                    {contentEditable ? (
                      <Box display={'flex'} alignItems={'center'}>
                        {
                          <Button disabled={!diff} color={'primary'} variant="contained" sx={{ ml: 2 }} onClick={() => flushUser()}>
                            Save
                          </Button>
                        }
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Divider />
                </>
              ) : (
                <></>
              )}
              {children}
            </>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
