export function formJson(event: any): Object {
  const data = new FormData(event.target);

  const value = Object.fromEntries(data.entries());

  return value;
}

export function capitalize(str: string): string {
  return str
    .split("")
    .map((ch, index) =>
      index == 0 || str[index - 1] == " " ? ch.toUpperCase() : ch
    )
    .join("");
}
