import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../../images/logo.svg';
import {
    Grid,
    Box,
    LinearProgress, styled
} from '@mui/material'
import { WELCOME_BACKGROUND_SRC } from "../../images/images";

const StyledWelcomeContainer = styled(Grid)({
    backgroundImage: `url(${WELCOME_BACKGROUND_SRC})`
})

const Welcome = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const [max, setMax] = useState(90)
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/register/shopify/${search}`, {
            credentials: 'include'
        })
        .then(r => r.json()
        .then(j => {
            if(j.success) {
                setMax(100);
            }
        }))
    }, [search]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === max && max === 100) {
                    navigate('/onboarding/register')
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, max);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [max, navigate])

    return (
        <StyledWelcomeContainer className="welcome" container>
            <Box sx={{ width: 'fit-content' }}>
                <img src={Logo} alt={'logo'}/>
                <LinearProgress className="loader" variant="determinate" value={progress} sx={{ mt: 5 }} />
            </Box>
        </StyledWelcomeContainer>
    )
};

export default Welcome