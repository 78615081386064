import WhiteLogo from "../images/earthshare_logo.svg";
import stripe from "../images/stripe.svg";
import { ReactComponent as PageBackground} from "../images/earthshare_dashboard.svg";
import { createTheme } from "@mui/material";
import { BackgroundImage,} from "./OnboardingBackground";
import { ONBOARDING_BACKGROUND_SRC } from "../images/images";


const OnboardingBackground = () => {
    return <BackgroundImage
      imageSrc={ONBOARDING_BACKGROUND_SRC}
    />
}

export const earthShareTheme = createTheme({
    palette: {
        primary: {
            light: '#DEFAFF',
            main: '#4bafc4'
        }
    },
    brightImgUrl: WhiteLogo,
    darkImgUrl : stripe,
    onboardingImage: OnboardingBackground,
    dashboardImage: PageBackground,
})