import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import type { User } from '../utils/userStorage';
import { clearStoredUser, getStoredUser, setStoredUser } from '../utils/userStorage'

const getUser = (user: User | null) => user ? user : null
const getDiff = (diff: boolean) => diff

export const useUser = () => {

    const [user, setUser] = useState<User | null>(getStoredUser())
    const [diff, setDiff] = useState<boolean>(false);
  
    const queryClient = useQueryClient()
  
    // call useQuery to update user data from server
    useQuery('user', () => getUser(user), {
      //enable only if a user is logged in
      enabled: !!user,
      onSuccess: (data) => setUser(data)
    })

    useQuery('diff', () => getDiff(diff), {
      //enable only if a user is logged in
      enabled: !!diff,
      onSuccess: (data) => setDiff(data)
    })
  
    const refreshUser = async () => {
      const session = await fetch(`${process.env.REACT_APP_API_URL}/api/shop/`, { credentials: 'include' });
  
      const payload = await session.json()
  
      updateUser(payload.shop, true)
  
      return {}
    }

    const flushUser = async () => {
      const session = await fetch(`${process.env.REACT_APP_API_URL}/api/shop/`, { 
        credentials: 'include', 
        method: 'PUT',
        body: JSON.stringify(user),
        headers: {
          'Content-Type': 'application/json'
        }
      })

      setDiff(false);
      queryClient.setQueryData('diff', false)
    }
  
  
    //meant to be called from useAuth
    const updateUser = (newUser: User, refresh: boolean = false) => {
      
      //set user in state
      setUser(newUser)
  
      //update user in localStorage
      setStoredUser(newUser)
  
      //pre-populate user profile in React Query client
      queryClient.setQueryData('user', newUser)

      if(!refresh) {
        setDiff(true)
        queryClient.setQueryData('diff', true)
      }
      
    }
  
    const clearUser = () => {
      //update state
      setUser(null)
  
      //remove from localStorage
      clearStoredUser()
  
      //reset user to null in query client
      queryClient.setQueryData('user', null)
    }
  
    return {
      user,
      updateUser,
      flushUser,
      diff,
      clearUser,
      refreshUser
    }
  }